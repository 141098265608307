import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import GoogleMaps from "../googlemaps"
import ContactButton from "../contactbutton"

const Content = () => {
  const portfolioDevData = useStaticQuery(graphql`
    query portfolioDevDataQuery {
      file(relativePath: { eq: "images/logo/logo0.png" }) {
        childImageSharp {
          fixed(width: 270) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const arloLogo = portfolioDevData.file.childImageSharp.fixed

  return (
    <div className="blog-details-wrapper pt--70 pb--100 bg-color-white">
      <div className="container">
        <div className="row">
          <div className="col-lg-7">
            <div className="post-single-title">
              <p>
                Basée dans le <strong>pays de Gex</strong> près de{" "}
                <strong>Genève</strong>, je crée des sites Internet vitrine
                et des sites d'e-commerce à destination des PME/PMI, professions
                indépendantes et associations
              </p>
              <ContactButton texte="Voir mon portfolio" lien="#portfolio"></ContactButton>
            </div>
            <div className="post-content">
              <p>
                Je propose diff&eacute;rents types de d&eacute;veloppement Web :
              </p>
              <ul>
                <li>
                  Le d&eacute;veloppement de sites Web sous{" "}
                  <strong>Wordpress</strong> (outil CMS)
                </li>
                <li>
                  Le d&eacute;veloppement de sites Web{" "}
                  <strong>statiques</strong>
                </li>
              </ul>
              <h3>Le d&eacute;veloppement de sites Web sous Wordpress</h3>
              <p>
                <Link
                  to="https://fr.wordpress.org/"
                  style={{ fontWeight: "bold" }}
                >
                  Wordpress
                </Link>{" "}
                est un outil de gestion de contenu ou CMS (Content Management
                System). L'avantage de Wordpress est qu'il vous permet d'éditer
                facilement le contenu de votre site Web via une interface
                d'administration. De plus, la multitude de plugins qui existent
                permettent d'y int&eacute;grer facilement des
                fonctionnalit&eacute;s relativement complexes ou
                n&eacute;cessitant une base de donn&eacute;es : module de
                paiement en ligne, catalogue de produits, galerie d'images,
                formulaire de contact complexe...
                <br />
                Les inconv&eacute;nients de Wordpress sont que l'outil
                n&eacute;cessite une maintenance (mises &agrave; jour)
                r&eacute;guli&egrave;re et qu'il peut poser des probl&egrave;mes
                de s&eacute;curit&eacute; notamment &agrave; cause de
                l'interface d'administration qui peut &ecirc;tre pirat&eacute;e.
              </p>
              <p><strong>Contactez-moi pour le développement de votre site Web sous Wordpress dans le pays de Gex, à Genève ou dans le canton de Vaud</strong></p>
             <p> <ContactButton></ContactButton>
              </p>
            
              <h3>Le d&eacute;veloppement de sites Web statiques</h3>
              <p>
                Les sites Web dits &laquo;&nbsp;statiques&nbsp;&raquo; (qui ne
                portent pas toujours si bien leur nom) sont des sites Web
                d&eacute;velopp&eacute;s en HTML, CSS et Javascript. Le terme
                &laquo;&nbsp;statique&nbsp;&raquo; se r&eacute;f&egrave;re au
                fait qu&rsquo;aucun langage dynamique (tel que PHP ou Python)
                n&rsquo;est utilis&eacute; et qu&rsquo;aucune base de
                donn&eacute;es n&rsquo;est n&eacute;cessaire. Il faut tout de
                même savoir que le terme "statique" est trompeur : les sites
                statiques ne sont pas du tout ennuyeux et, au premier abord, ne
                sont pas forcément si différents des sites Web développés sous
                Wordpress. De plus, en couplant un site Web statique &agrave;
                une API (interface de programmation), on peut le transformer en
                un site Web complexe nécessitant une base de données (site
                d&rsquo;e-commerce par exemple). Cependant, lorsque le site
                statique devient complexe, le d&eacute;veloppement devient plus
                compliqu&eacute; (et donc plus cher) qu&rsquo;avec Wordpress.
                <br></br>
                L'avantage des sites statiques concerne essentiellement la
                maintenance et la sécurité. En effet, le développeur n'a que peu
                de maintenance à gérer sur un site Web statique (ce qui veut
                dire peu de frais de maintenance pour le client) et les failles
                de sécurité sont limitées. Par contre, le client a peu de
                souplesse pour éditer lui-même son site Web.
              </p>
              <p><strong>Contactez-moi pour le développement de votre site Web statique dans le pays de Gex, à Genève ou dans le canton de Vaud</strong></p>
             <p> <ContactButton></ContactButton>
              </p>
              <h3>
                Que choisir entre un site Web Wordpress et un site Web statique
                ?
              </h3>
              <p>
                Vaut-il mieux un site Web statique ou un site Wordpress ? Tout
                dépend de votre projet, de vos besoins et de ce que vous comptez
                faire de votre site. N'hésitez pas à me{" "}
                <Link to="/contact">
                  <strong className="theme-color">contacter</strong>
                </Link>{" "}
                pour vous aider à analyser quel outil vous conviendrait le
                mieux.
              </p>
            </div>
          </div>
          <aside className="col-lg-5">
            <div
              style={{ border: "solid 1px var(--color-body)", padding: "2rem" }}
            >
              <div className="logo text-center">
                <Link to="/">
                  <Img fixed={arloLogo} alt="Logo SEO Alternative"/>
                </Link>
              </div>
              <h1
                className="h5 text-center"
                style={{ color: "var(--color-primary)", fontWeight: "normal" }}
              >
                SEO Alternative - Création de sites Internet dans le pays de
                Gex, à Genève et dans le canton de Vaud
              </h1>
              <GoogleMaps></GoogleMaps>
            </div>
          </aside>
        </div>
      </div>
    </div>
  )
}
export default Content
