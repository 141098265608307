import React from "react"

const GoogleMaps = props => {
  return (
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d44111.43737903334!2d5.957856756203955!3d46.29067276418038!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478c8a1d92062ab3%3A0x76e6b446e3edd3da!2s01170%20Crozet%2C%20France!5e0!3m2!1sfr!2sch!4v1645090536619!5m2!1sfr!2sch"
      title="Google Maps"
      height="450"
      style={{ border: 0, width: "100%" }}
      allowfullscreen=""
      loading="lazy"
    ></iframe>
  )
}

export default GoogleMaps;
