import React from "react";
import Layout from "../components/layout";
import Content from "../components/dev-services/content";
import Banner from "../components/banner";
import Project from "../components/dev-services/project";
import Seo from "../components/seo";




const DevServices = () => (
    <Layout>
        <Seo 
        title="Création de sites Internet dans le pays de Gex et à Genève - Marie-Luce Beraudo, SEO Alternative" 
        description="Basée dans le pays de Gex près de Genève, je développe des sites Web pour des PME/PMI, indépendants et associations. Je développe des sites Web sous Wordpress et des sites Web statiques."
        />

        <Banner title="Création de sites Internet dans le pays de Gex, en France et en Suisse"></Banner>
        <Content></Content>
        <Project></Project>
    </Layout>

)
export default DevServices;