import React from 'react';
import ProjectTwo from "../../elements/project/projectTwo";



const Project = () => {
  


    return (
        <div className="rn-portfolio-area rn-section-gap portfolio-style-1 line-separator" id="portfolio">
            <div className="container">
                <div className="row mb--10 align-items-center">
                    <div className="col-lg-12">
                        <div className="section-title">
                            <h2 className="title title-project">Portfolio</h2>

                            <span className="subtitle">Vous trouverez ci-dessous quelques-uns des sites Web que j'ai développés :</span>

                        </div>
                    </div>


                </div>


                <ProjectTwo />

            </div>
        </div>
    )
}
export default Project
