import React from 'react'
import { Link } from "gatsby";


const ContactButton = (props) => {



    return (
        <div className="button-group mt--20">
      <Link className="rn-button" to={props.lien}><span>{props.texte}</span></Link>
       </div>     
    )
}

ContactButton.defaultProps = {
    texte: "Me contacter",
    lien: "/contact"
}

export default ContactButton;